<template>
  <div name="lesson-resources" class="flex">
    <div
      v-for="(doc, index) in resources"
      :key="index"
      class="mb-2 cursor-pointer "
      @click="showResource(doc)"
      :title="doc.title"
    >
      <i
        class="fas text-blue-400 text-4xl mx-3"
        v-bind:class="resourceTypeIcon(doc)"
      ></i>
    </div>

    <modalfile
      :url="getSelectedDocumentPath()"
      :title="selectedDocument.title"
      @close="showModalFile = false"
      v-if="selectedDocument != undefined && showModalFile"
      v-show="showModalFile"
    ></modalfile>
  </div>
</template>

<script>
import { fileResourceMixin } from "@/mixins/file-ResourceMixin.js";

const modalfile = () => import("@/components/ModalFile.vue");

export default {
  name: "lesson-resources",
  mixins: [fileResourceMixin],
  props: {
    resources: { type: Array, required: true },
    popupDown: { type: Boolean, required: false },
  },
  computed: {

  },
  mounted() {},
  data() {
    return {
      selectedDocument: {},
      showModalFile: false,
      popDown: this.popupDown != undefined ? this.popupDown : false
    };
  },

  methods: {
     popupDirection() {
      if (this.popDown === true) {
        return  'bottom-center'
      } else {
        return 'top-center'
      }
    },
    showResource(resource) {
      this.selectedDocument = resource;
      this.showModalFile = true;
    },
    getSelectedDocumentPath() {
      return this.selectedDocument.folder + "/" + this.selectedDocument.url;
    },
    resourceTypeIcon(resource) {
      let imageExtensions = [];
      var ext = resource.url.split(".").pop();

      switch (ext) {
        case ("jpg", "png"):
          return "fa-file-image";
          break;
        case "pdf":
          return "fa-file-pdf";
          break;
        case ("doc", "docx"):
          return "fa-file-word";
          break;
        default:
          return "fa-file";
      }
    },
  },
  components: {
    modalfile,
  },
};
</script>
